import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthenticationEvent } from '@app/modello/authentication-event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject, catchError, concatMap, from, map, of, tap } from 'rxjs';
import { AccessoFacilitatoService } from './accesso-facilitato/accesso-facilitato.service';
import { ModalUtenteNonCensitoComponent } from './modal-utente-non-censito/modal-utente-non-censito.component';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private events$ = new Subject<IAuthenticationEvent>();

  constructor(
    private oauthService: OAuthService,
    private accessoFacilitatoService: AccessoFacilitatoService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.oauthService.events.subscribe((event) => {
      if (event.type === 'logout') {
        if (!this.isAccessoFacilitato()) {
          this.events$.next({ type: 'LOGOUT' });
          this.router.navigate(['/', 'home'], { replaceUrl: true });
        }
      } else if (event.type === 'token_received') {
        if (this.isAccessoFacilitato()) {
          this.accessoFacilitatoService.logout().subscribe();
        }
        this.events$.next({ type: 'LOGIN' });
      } else {
        // Non gestito
      }
    });
  }

  isAutenticatoConSisir(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  getEvents(): Observable<IAuthenticationEvent> {
    return this.events$.asObservable();
  }
  /**
   * Authenticates the user.
   * @param route Current activeted route snapshot.
   */
  loginSisir(url: string): void {
    this.oauthService.initCodeFlow(url);
  }

  /**
   * Logs out the user and clear credentials.
   */
  logoutSisir(): Observable<boolean> {
    this.oauthService.logOut();
    sessionStorage.clear();
    return of(true);
  }

  handleLogin(): Observable<boolean> {
    return from(this.oauthService.tryLoginCodeFlow()).pipe(
      concatMap(() => {
        let esito: Observable<boolean> | Promise<boolean> = of(false);
        if (this.oauthService.state) {
          if (this.oauthService.hasValidIdToken()) {
            this.accessoFacilitatoService.logout().subscribe();
            const redirectTo = decodeURIComponent(this.oauthService.state);
            esito = this.router.navigateByUrl(redirectTo, { replaceUrl: true });
          }
        } else {
          esito = this.router.navigate(['/'], { replaceUrl: true });
        }
        return esito;
      }),
      catchError((e) => {
        if (e?.status === 401) {
          this.modalService.open(ModalUtenteNonCensitoComponent, { centered: true, size: 'lg' });
          return this.router.navigate(['/'], { replaceUrl: true });
        } else {
          return this.router.navigate(['/'], { replaceUrl: true });
        }
      })
    );
  }

  loginAccessoFacilitato(accessoFacilitato: { tesseraSanitaria: string; codiceFiscale: string }): Observable<boolean> {
    return this.accessoFacilitatoService.login(accessoFacilitato).pipe(
      map(() => {
        this.events$.next({ type: 'LOGIN' });
        return true;
      })
    );
  }

  logoutAccessoFacilitato(): Observable<boolean> {
    return this.accessoFacilitatoService.logout().pipe(
      tap(() => {
        sessionStorage.clear();
        this.events$.next({ type: 'LOGOUT' });
      })
    );
  }

  isAccessoFacilitato(): boolean {
    return this.accessoFacilitatoService.isAccessoFacilitato();
  }

  logout(): void {
    let logout$: Observable<boolean>;
    if (this.isAccessoFacilitato()) {
      logout$ = this.logoutAccessoFacilitato();
    } else {
      logout$ = this.logoutSisir();
    }
    logout$.subscribe(() => {
      this.router.navigate(['/home'], { replaceUrl: true });
    });
  }
}
