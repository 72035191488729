<div class="container d-flex flex-wrap justify-content-center align-items-center">
  <div class="title-page mb-2 d-flex justify-content-center align-items-center row">
    <div class="col-12 col-lg-auto d-flex align-items-center justify-content-center">
      <h1>Scopri tutti i servizi sanitari digitali</h1>
    </div>
  </div>
  <div class="col-12">
    <div class="mb-5">
      <form class="row d-flex justify-content-center align-items-center flex-wrap" *ngIf="filtri">
        <div class="form-group col-xl-6 col-12">
          <input
            type="text"
            class="form-control form-ricerca form-grandezza mt-1"
            id="ricerca"
            name="ricerca"
            [(ngModel)]="ricerca"
            placeholder="Digita una o più parole chiave..."
            (change)="ricercaTestuale()"
            (click)="ricercaTestuale()"
          />
        </div>
        <div class="form-group col-xl-4 col-12">
          <select
            class="form-select form-control form-grandezza"
            id="categoria"
            name="categoria"
            [(ngModel)]="categoria"
            (change)="selezioneCategoria()"
          >
            <option value="">Categoria...</option>
            <option value="areaPersonale">Area personale</option>
            <option value="fse">Documenti e referti</option>
            <option value="screening" *ngIf="(delegante$ | async) === null">Screening oncologici</option>
            <option value="prenotazioni" *ngIf="(delegante$ | async) === null">Visite ed esami</option>
            <option value="perLaMamma" *ngIf="(delegante$ | async) === null && perLaMamma">Per la mamma</option>
            <option value="vaccinazioni" *ngIf="(delegante$ | async) === null">Cartella vaccinale</option>
            <option value="utilita" *ngIf="(delegante$ | async) === null">Utilità</option>
            <option value="anagrafe" *ngIf="(delegante$ | async) === null">Anagrafe</option>
          </select>
        </div>
      </form>
    </div>
    <div class="my-4" *ngIf="areaPersonale">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isAreaPersonaleCollapsed = !isAreaPersonaleCollapsed"
      >
        <h4 class="me-2">Area personale</h4>
        <i *ngIf="isAreaPersonaleCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isAreaPersonaleCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isAreaPersonaleCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            (ricerca?.toLowerCase()?.includes('profilo') || !ricerca || ricerca === '') && (delegante$ | async) === null
          "
        >
          <a routerLink="/area-personale/profilo" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <i class="bi bi-person icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Profilo</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            (ricerca?.toLowerCase()?.includes('taccuino') || !ricerca || ricerca === '') &&
            (delegante$ | async) === null
          "
        >
          <a routerLink="/area-personale/taccuino" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="bi bi-journal-bookmark icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Taccuino</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('gestisci') ||
            ricerca?.toLowerCase()?.includes('consensi') ||
            ricerca?.toLowerCase()?.includes('consenso') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/area-personale/gestisci-consensi" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <i class="bi bi-file-earmark-lock2 icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Gestisci consensi</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('gestisci') ||
            ricerca?.toLowerCase()?.includes('deleghe') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/area-personale/gestisci-deleghe" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="bi bi-people icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Gestisci deleghe</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="ricerca?.toLowerCase()?.includes('accessi') || !ricerca || ricerca === ''"
        >
          <a routerLink="/area-personale/accessi-fse" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg d-flex align-items-center">
                <i class="bi bi-box-arrow-in-right icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Accessi al mio FSE</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="ricerca?.toLowerCase()?.includes('notifiche') || !ricerca || ricerca === ''"
        >
          <a routerLink="/area-personale/notifiche-fse-ini" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg d-flex align-items-center">
                <i class="bi bi-bell icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Notifiche FSE-INI</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="prenotazioni && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isPrenotazioniCollapsed = !isPrenotazioniCollapsed"
      >
        <h4 class="me-2">Visite ed esami</h4>
        <i *ngIf="isPrenotazioniCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isPrenotazioniCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isPrenotazioniCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('prenotazioni') ||
            ricerca?.toLowerCase()?.includes('appuntamenti') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/prenotazioni/i-miei-appuntamenti" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="bi bi-calendar-week icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>I miei appuntamenti</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('prenota') ||
            ricerca?.toLowerCase()?.includes('appuntamento') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/prenotazioni/prenota-appuntamento" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <i class="bi bi-calendar-plus icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Prenota appuntamento</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('disdici') ||
            ricerca?.toLowerCase()?.includes('prenotazione') ||
            ricerca?.toLowerCase()?.includes('appuntamento') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/prenotazioni/disdici-appuntamento" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg d-flex align-items-center">
                <i class="bi bi-calendar-x icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Disdici appuntamento</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="my-4" *ngIf="fse">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isFseCollapsed = !isFseCollapsed"
      >
        <h4 class="me-2">Documenti e referti</h4>
        <i *ngIf="isFseCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isFseCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isFseCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('documenti') ||
            ricerca?.toLowerCase()?.includes('referti') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/fascicolo/documenti-e-referti" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg d-flex align-items-center">
                <i class="bi bi-file-earmark-text icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>I miei documenti</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="ricerca?.toLowerCase()?.includes('ricette') || !ricerca || ricerca === ''"
        >
          <a routerLink="/fascicolo/ricette-dematerializzate" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg d-flex align-items-center">
                <i class="bi bi-postcard icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Le mie ricette</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="screening && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isScreeningCollapsed = !isScreeningCollapsed"
      >
        <h4 class="me-2">Screening oncologici</h4>
        <i *ngIf="isScreeningCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isScreeningCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isScreeningCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('appuntamenti') ||
            ricerca?.toLowerCase()?.includes('esiti') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/screening/appuntamenti-esiti" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="ci icon-icona-menu-appuntamenti-screening icona-md icona-white icona-app"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Appuntamenti ed esiti</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="ricerca?.toLowerCase()?.includes('contatti') || !ricerca || ricerca === ''"
        >
          <a routerLink="/screening/contatti-screening" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <i class="ci icon-icona-menu-contatti-screening icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Contatti screening</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('aderisci') ||
            ricerca?.toLowerCase()?.includes('campagna') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/screening/aderisci-campagna" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="ci icon-icona-menu-campagna-screening icona-md icona-white icona-app"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Aderisci alla campagna</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="perLaMamma && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isPerLaMammaCollapsed = !isPerLaMammaCollapsed"
      >
        <h4 class="me-2">Per la mamma</h4>
        <i *ngIf="isPerLaMammaCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isPerLaMammaCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isPerLaMammaCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="ricerca?.toLowerCase()?.includes('gravidanze') || !ricerca || ricerca === ''"
        >
          <a routerLink="/per-la-mamma/le-mie-gravidanze" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-secondary">
              <div class="icona maschera-home-svg py-6 d-flex align-items-center">
                <div class="px-2">
                  <img src="../../../assets/per-la-mamma/baby-white.svg" class="baby-white" alt="Le mie gravidanze" />
                </div>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Le mie gravidanze</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('diario') ||
            ricerca?.toLowerCase()?.includes('gravidanza') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/per-la-mamma/diario-della-gravidanza" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="bi bi-journal-bookmark icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Diario della gravidanza</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('gravidanza') ||
            ricerca?.toLowerCase()?.includes('fasi') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/per-la-mamma/le-fasi-della-gravidanza" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <div class="px-2">
                  <img
                    src="../../../assets/per-la-mamma/pregnant-woman-white.svg"
                    class="img-mother-white"
                    alt="Le fasi della gravidanza"
                  />
                </div>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Le fasi della gravidanza</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('prestazioni') ||
            ricerca?.toLowerCase()?.includes('agevolazioni') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/per-la-mamma/prestazioni-agevolazioni" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <i class="bi bi-cash-stack icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Prestazioni ed agevolazioni</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('enti') ||
            ricerca?.toLowerCase()?.includes('rosa') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a href="/utilita/cerca-ente?tipologia=enti-rosa" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <i class="bi bi-hospital icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Enti rosa</h5>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="vaccinazioni && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isVaccinazioniCollapsed = !isVaccinazioniCollapsed"
      >
        <h4 class="me-2">Cartella Vaccinale</h4>
        <i *ngIf="isVaccinazioniCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isVaccinazioniCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isVaccinazioniCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('corredo') ||
            ricerca?.toLowerCase()?.includes('vaccinale') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/cartella-clinica-vaccinale/corredo-vaccinale" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center cartella-vacc">
                <div class="px-2"><img class="corredo-vacc icona-app" alt="Corredo vaccinale" /></div>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Corredo vaccinale</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('preferenze') ||
            ricerca?.toLowerCase()?.includes('contatti') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a routerLink="/cartella-clinica-vaccinale/preferenze-contatti" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center preferenze-cont">
                <div class="px-2"><img class="preferenze icona-app" alt="Preferenze e Contatti" /></div>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Contatti</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-primary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('punti') ||
            ricerca?.toLowerCase()?.includes('vaccinali') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a href="/utilita/cerca-ente?tipologia=centri-vaccinali" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center cartella-vacc">
                <div class="px-2"><img class="punti-vacc icona-app" alt="Punti Vaccinali" /></div>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Punti Vaccinali</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="utilita && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isUtilitaCollapsed = !isUtilitaCollapsed"
      >
        <h4 class="me-2">Utilità</h4>
        <i *ngIf="isUtilitaCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isUtilitaCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isUtilitaCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('cerca') ||
            ricerca?.toLowerCase()?.includes('ente') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a role="button" routerLink="/utilita/cerca-ente" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex border-primary">
              <div class="icona maschera-blue-svg py-4 d-flex align-items-center">
                <div class="px-2 cerca-ente">
                  <img class="img-ente" title="Cerca ente" alt="Cerca ente" />
                </div>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Cerca ente</h5>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="anagrafe && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter p-0 d-flex align-items-start"
        type="button"
        (click)="isAnagrafeCollapsed = !isAnagrafeCollapsed"
      >
        <h4 class="me-2">Anagrafe</h4>
        <i *ngIf="isAnagrafeCollapsed" class="bi bi-chevron-down icona-sm icona-secondary" title="Apri Menu"></i>
        <i *ngIf="!isAnagrafeCollapsed" class="bi bi-chevron-up icona-sm icona-secondary" title="Chiudi Menu"></i>
      </button>
      <div [ngbCollapse]="isAnagrafeCollapsed" class="dont-collapse-xl row">
        <div
          class="autore col-xl-4 col-lg-6 col-12"
          *ngIf="
            ricerca?.toLowerCase()?.includes('scelta') ||
            ricerca?.toLowerCase()?.includes('medico') ||
            !ricerca ||
            ricerca === ''
          "
        >
          <a role="button" (click)="accediASceltaMedico()" class="contenitori-home py-3 pe-3 w-100">
            <div class="box-doc box box-home d-flex">
              <div class="icona maschera-home-svg py-4 d-flex align-items-center">
                <i class="bi bi-person-plus icona-md icona-white px-2"></i>
              </div>
              <div class="informazioni align-self-center overflow-hidden">
                <h5>Accedi a scelta medico</h5>
              </div>
              <div class="ms-auto d-flex flex-column w-auto p-2">
                <i class="bi bi-key-fill icona-secondary icona-sm"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="my-4" *ngIf="error">
      <section class="error-404">
        <div class="container">
          <div class="inner-content">
            <div class="error-title"><span></span></div>
            <h2 class="error-text">Nessun elemento trovato!</h2>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
